import { KrtiPayService } from './api'

const krtiWebUrl = process.env.REACT_APP_WEB_BASE_URL;

export const Customerlist = async (req) => {
    
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/customer-mangement/Customer/${req}
        `)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getCustomerBankDetails = async (id) => {
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/customer-mangement/getCustomerBank/${id}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getUserWalletDetails = async (id,requestBody) => {
    const { offset, limit, user_id, brand_id,search ,master_id } = requestBody

    try {
        return await KrtiPayService.get(`${krtiWebUrl}/customer-mangement/getUserWallet/${id}?offset=${offset}&limit=${limit}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getVoucherDetails = async (id,requestBody) => {
    const { offset, limit, user_id, brand_id,search ,master_id } = requestBody
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/customer-mangement/get-vouchers/${id}?offset=${offset}&limit=${limit}&search=${search}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const getCustomerTransactionDetails = async (requestBody) => {
    const { offset, limit, user_id, brand_id,search ,master_id } = requestBody
    
    try {
        return await KrtiPayService.get(`${krtiWebUrl}/customer-mangement/get-master-transaction/${master_id}?offset=${offset}&limit=${limit}`)
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}
