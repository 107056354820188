import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import CreateIcon from "../../assets/images/create_icon.svg";
import {
  updateCashBackRules,
  getCashbackRules,
} from "../../services/cashback.service";
import { useParams } from "react-router-dom";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import { GetMerchantCategory } from "../../services/Category.service";
import TextEditor from "../TextEditor/TextEditor";
import { calculatePercentage } from "../../utils/helpers/helper";
import { Parser } from "htmlparser2";
import Select from "react-select";
import { default as ReactSelect, components } from "react-select";
import { DomHandler } from "domhandler";

const fileType = "Cashback";
function Editcashbackrule({ brand = {}, back, product }) {
  

  const deserialize = (html) => {
    const parseNode = (node) => {
      if (node.type === "text") {
        let attributes = {};
        if (
          node.parent &&
          (node.parent.name === "strong" ||
            node.parent.name === "em" ||
            node.parent.name === "u")
        ) {
          if (node.parent.name === "strong") attributes.bold = true;
          if (node.parent.name === "em") attributes.italic = true;
          if (node.parent.name === "u") attributes.underline = true;
        }
        return { ...attributes, text: node.data };
      }

      switch (node.name) {
        case "p":
          return {
            type: "paragraph",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "a":
          return {
            type: "link",
            url: node.attribs.href,
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "blockquote":
          // Assuming all block quotes are wrapped in a paragraph tag
          return {
            type: "block-quote",
            children: [
              {
                type: "paragraph",
                children: node.children ? node.children.map(parseNode) : [],
              },
            ],
          };
        case "ul":
          return {
            type: "bulleted-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "ol":
          return {
            type: "numbered-list",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "li":
          return {
            type: "list-item",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h1":
          return {
            type: "heading-one",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "h2":
          return {
            type: "heading-two",
            children: node.children ? node.children.map(parseNode) : [],
          };
        case "div":
          // Assuming divs are used for text alignment
          const align = node.attribs.style.split(":")[1].trim();
          return {
            align,
            children: node.children ? node.children.map(parseNode) : [],
          };
        default:
          // Check if the node has only one child
          if (node.children && node.children.length === 1) {
            return parseNode(node.children[0]);
          } else {
            return node.children ? node.children.map(parseNode) : [];
          }
      }
    };

    try {
      const handler = new DomHandler();
      const parser = new Parser(handler);

      parser.write(html || "");
      parser.end();

      const document = handler.dom.map(parseNode);
      return document.length > 0
        ? document
        : [{ type: "paragraph", children: [{ text: "" }] }];
    } catch (error) {
      console.error("Error occurred during deserialization:", error);
      return [
        {
          type: "paragraph",
          children: [{ text: "Error occurred during deserialization" }],
        },
      ];
    }
  };

  const [privacyPolicy, setPrivacyPolicy] = useState(
    brand?.privacy_policy || ""
  );
  const [termsAndCondition, setTermsAndCondition] = useState(
    brand?.terms_condition || ""
  );
  const [stepsToRedeem, setStepsToRedeem] = useState(
    brand?.step_to_redeem || ""
  );

  // const user = useSelector(selectCurrentUser)?.userDetail;

  const { id } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [selectedList, setselectedproductlist] = useState([]);
  console.log('selectedList::: ', selectedList);

  const today = new Date().toISOString().split("T")[0];
  const formattedToday = new Date().toISOString().split("T")[0];
  // const [isMultipleRedemption, setIsMultipleRedemption] = useState(false);

  const initialRedemptionType = brand?.redemption_type === "multirredemption";

  const [isMultipleRedemption, setIsMultipleRedemption] = useState(
    initialRedemptionType
  );

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;

    setFieldValue(
      "redemption_type",
      isChecked ? "multirredemption" : "perredemption"
    );
    setIsMultipleRedemption(isChecked);
    if (!isChecked) setFieldValue("redemption_count", 1);
  };

  const [state, setState] = useState({ optionSelected: [] });
  console.log("state::: ", state.optionSelected);

  const handleChangetest4 = (selectedOptions) => {
    console.log('selectedOptions::: ', selectedOptions);
    setState((prevState) => ({
      ...prevState,
      optionSelected: selectedOptions || [],
    }));
    setFieldValue("products", selectedOptions);
    setselectedproductlist(selectedOptions || [])
    
  };

  const Option = (props) => {
    console.log("props::: ", props);
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <img
            src={props.data.logo}
            alt={props.data.product_name}
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
          <label>{props.label}</label>
          {/* <label>{props.logo}</label> */}
        </components.Option>
      </div>
    );
  };




  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      rules_name: brand?.rules_name,

      cash_back_on: brand?.cash_back_on,
      startDate: new Date(brand?.start_date).toISOString().split("T")[0],
      endDate: new Date(brand?.end_date).toISOString().split("T")[0],
      offer_type: brand?.offer_type,
      offer_value: brand?.offer_value,
      min_spend: brand?.min_spend,
      upper_limit: brand?.upper_limit,
      logo: brand?.campaign_logo,
      banner: brand?.banner_image,
      selectedLogo: brand?.campaign_logo,
      privacyPolicy: brand?.privacy_policy,
      termsAndCondition: brand?.terms_condition,
      privacyPolicy_raw: brand?.privacy_policy_raw,
      termsAndCondition_raw: brand?.terms_condition_raw,
      stepsToRedeem_raw: brand?.step_to_redeem_raw,
      stepsToRedeem: brand?.step_to_redeem,
      allow_coupon: brand?.allow_coupon,
      allow_voucher: brand?.allow_voucher,
      category_id: brand?.category_id,
      redemption_count: brand?.redemption_count,
      redemption_type: brand?.redemption_type,
      redmption_interval: brand?.redmption_interval,
      products:selectedList
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      rules_name: Yup.string()
        // .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters and spaces are allowed')
        .max(20, "Cashback Rule Name must be at most 20 characters")
        .required("Cashback Rule Name is required"),
      cash_back_on: Yup.string().required("Coupon Count is required"),
      category_id: Yup.string().required("Category is required"),
      startDate: Yup.string()
        .test(
          "is-greater-or-equal-today",
          "startDate must be greater than or equal to today's date",
          function (value) {
            if (!value) return false;
            return new Date(value) >= new Date(formattedToday);
          }
        )
        .required("startDate is required"),
      endDate: Yup.string().required("endDate is required"),
      offer_type: Yup.string().required("offer type is required"),
      offer_value: Yup.string()
        .test({
          name: "offer_value",
          exclusive: false,
          test: function (value, { createError }) {
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );
            if (isNumeric) {
              if (this.parent.offer_type === "Percentage") {
                if (parseFloat(value) < 100) return true; // Reset error
                else
                  return createError({
                    message:
                      "Value must be a number and below 100 for percentage offers",
                  });
              } else {
                if (parseFloat(value) < parseFloat(this.parent.min_spend))
                  return true; // Reset error
                else
                  return createError({
                    message: "Value must be a number and below min spend",
                  });
              }
            } else {
              return true; // Reset error for non-numeric values
            }
          },
        })
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        )
        .required("Value is required"),
      min_spend: Yup.string()
        .required("Min spend  is required")
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Min spend must be a positive number with up to three decimal places"
        ),
      upper_limit: Yup.string()
        .matches(
          /^\d+(\.\d{1,3})?$/,
          "Upper limit must be a positive number with up to three decimal places"
        )
        .test({
          name: "upper_limit",
          exclusive: false,
          test: function (value, { parent }) {
            // Check if the value is a valid number
            const isNumeric = /^[1-9][0-9]*([.][0-9]+)?$|^0[.][0-9]+$/.test(
              value
            );

            // Skip validation for "Value" offer type or empty value
            if (parent.offer_type === "Value" || !+value) {
              return true;
            }

            // Return false if value is not numeric
            if (!isNumeric) {
              return false;
            }

            // Specific validation for "Percentage" offer type (value must be positive)
            if (parent.offer_type === "Percentage") {
              return parseFloat(value) > 0;
            }

            // Skip validation for other offer types
            return true;
          },
        }),
      redemption_count:
        isMultipleRedemption &&
        Yup.number()
          .typeError("Value must be a number")
          .positive("Count must be greater than 0")
          .integer("Count must be an integer")
          .required("Count must be required"),
      privacyPolicy: Yup.string()
        .max(800, "Privacy Policy  must be at most 800 characters")
        .required("Privacy Policyis required"),
      termsAndCondition: Yup.string()
        .max(800, "Terms and Condition  must be at most 800 characters")
        .required("Terms and Condition is required"),
      stepsToRedeem: Yup.string()
        .max(800, "Steps To Redeem  must be at most 800 characters")
        .required("Steps To Redeem required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log('values::: ', values);
      try {
        const payload = {
          ...values,
          brand_id: id,
          rules_id: brand.id,
          min_spend: +values.min_spend,
          upper_limit:
            values?.offer_type === "Percentage" ? +values?.upper_limit : 0,
          discount_value:
            values?.offer_type === "Percentage"
              ? +values?.upper_limit ||
                calculatePercentage(+values?.offer_value, +values.min_spend)
              : +values?.offer_value,
          category_id: +values?.category_id,
          privacyPolicy_raw: deserialize(values?.privacyPolicy),
          termsAndCondition_raw: deserialize(values?.termsAndCondition),
          stepsToRedeem_raw: deserialize(values?.stepsToRedeem),
          redemption_count:
            values?.redemption_type == "perredemption"
              ? 1
              : +values?.redemption_count,
          redemption_type: values.redemption_type,
        };

        const res = await updateCashBackRules(payload);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  console.log("test--------------",product)
  const handleFileChange = async (event, type = "logo") => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          if (type === "banner") {
            setFieldValue("banner", res?.data?.data);
          } else {
            setFieldValue("logo", res?.data?.data);
            setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
          }
        }
      }
    } catch (error) {}
  };

  const removeLogo = (type = "logo") => {
    if (type === "banner") {
      setFieldValue("banner", "");
    } else {
      setFieldValue("logo", "");
      setFieldValue("selectedLogo", "");
    }
  };

  const Cashbackoption = [
    { key: "Product", value: "Product" },
    { key: "Cart", value: "Cart" },
    { key: "Promotions", value: "Promotions" },
  ];

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue("offer_value", "");
  };
  useEffect(() => {
    const fetchmerchantCategory = async () => {
      try {
        const resp = await GetMerchantCategory(id);
        if (resp?.status === 200 && resp?.data?.statusCode === 200) {
          setCategoryList(resp?.data?.data);
        } else {
          toast.error(resp?.message || resp?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    fetchmerchantCategory();

    if (product && product.length > 0) {
      const preselectedOptions = product.map((p) => ({
        label: p.product_name,
        value: p.product_id,
        ...p,
      }));
  
      setState({ optionSelected: preselectedOptions });
      setselectedproductlist(preselectedOptions);
      console.log("Preselected Options:", preselectedOptions);
    }
  }, [product]);

  return (
    <>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div>
          {/* <h5 className="fontSize20 fontWeight600 blackClr_text">
            Edit CashBack Rules{" "}
          </h5> */}
        </div>
        <div>
          {/* <button
            className="MainButton neverything_clr gap8"
            type="button"
            onClick={handleClose}
          >
            <img src={BackIcon} alt="BackIcon" />
            <span> Back </span>
          </button> */}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb_24">
          <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
            Cashback Logo
          </label>
          {!values?.selectedLogo && (
            <label htmlFor="selectedLogo" className="uploadImg_btn gap12">
              <img src={UploadImg} alt="UploadImg" />
              <span>Upload Cashback Logo</span>
              <input
                id="selectedLogo"
                type="file"
                name="logo"
                value={values.logo}
                accept="image/*" // Accept all image file types
                onChange={handleFileChange}
                hidden
              />
            </label>
          )}

          {values?.selectedLogo && (
            <div className="ImgFrame position_relative">
              <img
                className="brandImgSelectlogo"
                src={values?.selectedLogo}
                width={100}
                height={100}
                alt="selected logo"
              />
              <div className="imgcloseBtn ">
                <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
              </div>
            </div>
          )}

          {!values.logo && touched.logo ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.logo}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
            Cashback Banner
          </label>
          {!values?.banner && (
            <label htmlFor="banner" className="uploadImg_btn gap12">
              <img src={UploadImg} alt="UploadImg" />
              <span>Upload Cashback banner</span>
              <input
                id="banner"
                type="file"
                name="banner"
                value={values.banner}
                accept="image/*" // Accept all image file types
                onChange={(e) => handleFileChange(e, "banner")}
                hidden
              />
            </label>
          )}

          {values?.banner && (
            <div className="bannerImgFrame position_relative">
              <img
                className="UcbImg"
                src={values?.banner}
                width={100}
                height={100}
                alt="selected Banner"
              />
              <div className="imgcloseBtn ">
                <img
                  src={CloseIcon}
                  alt="CloseIcon"
                  onClick={() => removeLogo("banner")}
                />
              </div>
            </div>
          )}

          {!values.banner && touched.banner ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.banner}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Cashback Rule Name
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Cashback Rules Name"
            id="rules_name"
            name="rules_name"
            value={values.rules_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.rules_name && touched.rules_name ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.rules_name}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Start Date
          </label>
          <input
            className="MainInput"
            type="date"
            id="startDate"
            name="startDate"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.startDate}
            min={today}
            max="9999-12-31"
          />
          {errors.startDate && touched.startDate ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.startDate}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="fn"
          >
            End Date
          </label>
          <input
            className="MainInput"
            type="date"
            id="endDate"
            name="endDate"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.endDate}
            min={values.startDate} // Set minimum date to the selected start date
            max="9999-12-31"
            // max={values.endDate} // Set maximum date to the end of the current month
          />
          {errors.endDate && touched.endDate ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.endDate}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="brand_id"
          >
            Category
          </label>
          <select
            className="selectMainInput"
            id="category_id"
            name="category_id"
            value={values.category_id}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value="">Select Category</option>

            {categoryList.map((category, i) => (
              <option key={i} value={category.category_id}>
                {category.category_name}
              </option>
            ))}
          </select>
          {errors.category_id && touched.category_id ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.category_id}
            </p>
          ) : (
            <></>
          )}
        </div>
        {values.cash_back_on === "Product" && (
          <div className="mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Product Select
            </label>

            <ReactSelect
              options={product}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={handleChangetest4}
              value={state.optionSelected}
              onBlur={(e) => {
                handleBlur(e);
                setFieldTouched("product", true);
              }}
            />

            {errors.category_type && touched.category_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category_type}
              </p>
            ) : (
              <></>
            )}
          </div>
        )}

        {/* {!id && ( */}
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="brand_id"
          >
            Cashback On
          </label>
          <select
            className="selectMainInput"
            id="cash_back_on"
            name="cash_back_on"
            value={values.cash_back_on}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value="">Select Cashback On</option>

            {Cashbackoption.map((count) => (
              <option key={count.id} value={count.id}>
                {count.value}
              </option>
            ))}
          </select>
          {errors.cash_back_on && touched.cash_back_on ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.cash_back_on}
            </p>
          ) : (
            <></>
          )}
        </div>
        {/* )} */}

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="toggleValueInput"
          >
            Cashback Option type
          </label>
          <div className="displayFlex alignItem_center gap12">
            <div className="prefer_method">
              <input
                type="radio"
                id="offer_value"
                name="offer_type"
                value="Value"
                checked={values.offer_type === "Value"}
                onChange={(e) => handleTypeChange(e)}
              />
              <label htmlFor="offer_value">Amount</label>
            </div>
            <div className="prefer_method">
              <input
                type="radio"
                id="offer_percentage"
                name="offer_type"
                value="Percentage"
                checked={values.offer_type === "Percentage"}
                onChange={(e) => handleTypeChange(e)}
              />
              <label htmlFor="offer_percentage">Percentage</label>
            </div>
          </div>
          {errors.offer_type && touched.offer_type ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.offer_type}
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            {values?.offer_type === "Value" ? "Amount" : "Percentage"}
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Value"
            id="offer_value"
            name="offer_value"
            value={values.offer_value}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.offer_value && touched.offer_value ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.offer_value}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Minimum spend
          </label>
          <input
            className="MainInput"
            type="text"
            placeholder="Enter Value"
            id="min_spend"
            name="min_spend"
            value={values.min_spend}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {errors.min_spend && touched.min_spend ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.min_spend}
            </p>
          ) : (
            <></>
          )}
        </div>
        {values.offer_type === "Percentage" && (
          <div className="mb_24">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Upper limit
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Value"
              id="upper_limit"
              name="upper_limit"
              value={values.upper_limit}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.upper_limit && touched.upper_limit ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.upper_limit}
              </p>
            ) : (
              <></>
            )}
          </div>
        )}

        <div className="mb_24 col-md-6">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="redemption_interval"
          >
            Redemption Interval
          </label>
          <select
            className="selectMainInput"
            id="redemption_interval"
            name="redemption_interval"
            value={values.redemption_interval}
            onBlur={handleBlur}
            onChange={(e) =>
              setFieldValue("redmption_interval", e.target.value)
            }
          >
            <option value="NIL">Select Interval</option>
            <option key="WEEKLY" value="WEEKLY">
              WEEKLY
            </option>
            <option key="MONTHLY" value="MONTHLY">
              MONTHLY
            </option>
          </select>
        </div>

        <div className="mb_24 col-md-6">
          <div className="displayFlex justifyContent_spacebetween alignItem_center">
            <div className="displayFlex alignItem_center gap12">
              <div>
                <label className="fontSize14 fontWeight500 blackClr_text display_block">
                  Per Person Redemption
                </label>
              </div>
              <div>
                <label className="switch">
                  <input
                    className="switch-input"
                    type="checkbox"
                    checked={isMultipleRedemption}
                    onChange={handleSwitchChange}
                  />
                  <span
                    className="switch-label"
                    data-on="Multiple Time"
                    data-off="Single Time"
                  />
                  {/* {values.multiple_redemption ? 'Multiple Time' : 'Single Time'} */}
                  <span className="switch-handle" />
                </label>
              </div>
            </div>
          </div>
          <div>
            {isMultipleRedemption && (
              <input
                type="text"
                className="MainInput"
                value={values.redemption_count}
                onChange={handleChange}
                onBlur={handleBlur}
                name="redemption_count"
                min="0"
                maxLength={5}
              />
            )}
            {isMultipleRedemption &&
            errors.redemption_count &&
            touched.redemption_count ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.redemption_count}
              </p>
            ) : null}
          </div>
        </div>

        {/* 
        <div className="toggleContainer">
          <button
            type="button"
            className={`toggleButton ${values.multiple_redemption ? 'active' : ''} switch-label`}
            onClick={() => {
              setFieldValue('multiple_redemption', !values.multiple_redemption);
              setIsMultipleRedemption(!values.multiple_redemption);
            }}
          >
            {values.multiple_redemption ? 'Multiple Time' : 'Single Time'}
          </button>
        </div> */}
        {/* </div> */}
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="email"
          >
            Privacy Policy
          </label>
          {/* <textarea
            className="MainTextarea"
            placeholder="Privacy Policy"
            id="privacyPolicy"
            name="privacyPolicy"
            value={values.privacyPolicy}
            onBlur={handleBlur}
            onChange={handleChange}
            rows={3}
          ></textarea> */}
          <TextEditor
            name="privacyPolicy"
            value={privacyPolicy}
            values={values.privacyPolicy_raw}
            onChange={(value) => {
              setPrivacyPolicy(value);
              setFieldValue("privacyPolicy", value);
            }}
            // onChange={ setPrivacyPolicy}
            setFieldValue={setFieldValue}
            fieldName="privacyPolicy"
            onBlur={handleBlur}
          />
          {errors.privacyPolicy && touched.privacyPolicy ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.privacyPolicy}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="termsAndCondition"
          >
            Terms And Condition
          </label>
          {/* <textarea
            className="MainTextarea"
            placeholder="Terms And Condition"
            id="termsAndCondition"
            name="termsAndCondition"
            value={values.termsAndCondition}
            onBlur={handleBlur}
            onChange={handleChange}
            rows={3}
          ></textarea> */}
          <TextEditor
            name="termsAndCondition"
            value={termsAndCondition}
            values={values.termsAndCondition_raw}
            onChange={(value) => {
              setTermsAndCondition(value);
              setFieldValue("termsAndCondition", value);
            }}
            setFieldValue={setFieldValue}
            fieldName="termsAndCondition"
          />
          {errors.termsAndCondition && touched.termsAndCondition ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.termsAndCondition}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="mb_24">
          <label
            className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
            htmlFor="stepsToRedeem"
          >
            Steps To Redeem
          </label>
          {/* <textarea
            className="MainTextarea"
            placeholder="Steps To Redeem"
            id="stepsToRedeem"
            name="stepsToRedeem"
            value={values.stepsToRedeem}
            onBlur={handleBlur}
            onChange={handleChange}
            rows={3}
          ></textarea> */}
          <TextEditor
            name="stepsToRedeem"
            value={stepsToRedeem}
            values={values.stepsToRedeem_raw}
            onChange={(value) => {
              setStepsToRedeem(value);
              setFieldValue("stepsToRedeem", value);
            }}
            fieldName="stepsToRedeem"
            setFieldValue={setFieldValue}
          />
          {errors.stepsToRedeem && touched.stepsToRedeem ? (
            <p className="fontSize12 fontWeight500 errorClr_text mt_8">
              {errors.stepsToRedeem}
            </p>
          ) : (
            <></>
          )}
        </div>
        {/* <div className="mb_24">
          <div className="displayFlex spaceBetween pb20">
            <div>Allow Cashback if any coupon(campaign) code is applied</div>
            <div className=" " style={{ display: "grid" }}>
              <div className="switch">
                <input
                  type="checkbox"
                  id="twoFactorAuth"
                  checked={values.allow_coupon}
                  onChange={(e) => setFieldValue("allow_coupon", !values.allow_coupon)}
                />
                <label htmlFor="twoFactorAuth" />
              </div>
            </div>
          </div>
        </div>
        <div className="mb_24">
          <div className="displayFlex spaceBetween pb20">
            <div>Allow Cashback if any voucher code is applied</div>
            <div className=" " style={{ display: "grid" }}>
              <div className="switch">
                <input
                  type="checkbox"
                  id="twoFactorAuth"
                  checked={values.allow_voucher}
                  onChange={(e) => setFieldValue("allow_voucher", !values.allow_voucher)}
                />
                <label htmlFor="twoFactorAuth" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="mb_24">
          <div className="inputCheckbox">
            <input
              type="checkbox"
              id="twoFactorAuth"
              checked={values.allow_coupon}
              onChange={(e) =>
                setFieldValue("allow_coupon", !values.allow_coupon)
              }
            />
            <label htmlFor="twoFactorAuth">
              Allow Cashback if any coupon(campaign) code is applied
            </label>
          </div>

          {/* <div>Allow Cashback if any coupon(campaign) code is applied</div>
            <div className=" " style={{ display: "grid" }}>
              <div className="switch">
                <input
                  type="checkbox"
                  id="twoFactorAuth"
                  checked={values.allow_coupon}
                  onChange={(e) => setFieldValue("allow_coupon", !values.allow_coupon)}
                />
                <label htmlFor="twoFactorAuth" />
              </div>
            </div> */}
        </div>
        <div className="mb_24">
          <div className="inputCheckbox">
            <input
              type="checkbox"
              id="voucherCodeApply"
              checked={values.allow_voucher}
              onChange={(e) =>
                setFieldValue("allow_voucher", !values.allow_voucher)
              }
            />
            <label htmlFor="voucherCodeApply">
              Allow Cashback if any voucher code is applied
            </label>
          </div>

          {/* 
          <div className="displayFlex spaceBetween pb20">
            <div>Allow Cashback if any voucher code is applied</div>
            <div className=" " style={{ display: "grid" }}>
              <div className="switch">
                <input
                  type="checkbox"
                  id="twoFactorAuth"
                  checked={values.allow_voucher}
                  onChange={(e) => setFieldValue("allow_voucher", !values.allow_voucher)}
                />
                <label htmlFor="twoFactorAuth" />
              </div>
            </div>
          </div> */}
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={CreateIcon} alt="CreateIcon" className="width_20px" />
          <span>Save CashBack</span>
        </button>
      </form>
    </>
  );
}

export default Editcashbackrule;
