import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { getUserWalletDetails } from "../../services/customer.service";
import { formatCurrency } from "../../utils/helpers/helper";
import PaginatedTable from "../common/PaginatedTable";

const CustomerWallet = () => {
  const [walletData, setWalletData] = useState(null);
  console.log('walletData::: ', walletData);
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const handleTogglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (ranges) => {
    const temp = {
      startDate: ranges["range1"]["startDate"],
      endDate: ranges["range1"]["endDate"],
    };
    setRange(temp);
  };
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  useEffect(() => {
    getWalletData(id);
  }, [id, currentPage, itemsPerPage, searchKey, range]);

  const [column, setColumn] = useState([
    // {
    //   heading: () => "Action",
    //   cell: (row) => (
    //     <div>
    //       {/* <button className="emptyBtn" onClick={() => handleShowedit(row)}>
    //         <img className="CyanSky_filter" src={EditIcon} alt="EditIcon" />
    //       </button> */}
    //       <button className="emptyBtn" onClick={() => handleShowView(row)}>
    //         <img src={ViewIcon} alt="ViewIcon" />
    //       </button>
    //     </div>
    //   ),

    //   hidden: false,
    //   // width: "120",
    //   // align: "left",
    //   id: 1,
    // },
    {
      heading: () => "ID",
      cell: (row) => (
        <div className="textWrap_Wrap">{row["id"] ? row["id"] : "-"}</div>
      ),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    // row["brand_name"] === " " ? "-" : row["brand_name"]
    {
      heading: () => "Amount",
      cell: (row) =>
        row["amount"] != null ? formatCurrency(row["amount"]) : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },

    {
      heading: () => "Type",
      cell: (row) =>
        row["wallet_transaction_type"] ? row["wallet_transaction_type"] : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => "Created Date",

      cell: (row) => (row["createdDate"] ? row["createdDate"] : "-"),
      hidden: false,
      hidden: false,
      width: "100",
      id: 4,
    },

    // {
    //   heading: () => "Amount",
    //   cell: (row) => row["amount"] != null ? formatCurrency(row["amount"]) : "-",
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 6,
    // },

    // {
    //   heading: () => "Redeemed",
    //   cell: (row) => (
    //     <span className={row?.redeemed ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"}>
    //       {row["redeemed"] ? "Redeemed" : "Not Redeemed"}
    //     </span>
    //   ),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 8,
    // },
  ]);

  const formatDate = (dateString) => {
    const parts = dateString.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Month is zero-based in Date object
    const year = parseInt(parts[2]);
    return new Date(year, month, day).toLocaleDateString("en-GB");
  };

  const getWalletData = async (id) => {
    const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        // search: searchKey,
        // startDate: convertToISOString(range.startDate),
        // endDate: convertToISOString(range.endDate),
      };
      const res = await getUserWalletDetails(id,requestBody);
      console.log('res::: ', res);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setWalletData(res?.data?.data?.wallet_transaction);
        setTotalCount(res?.data?.data?.count)
      } else {
        // toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error.message);
      toast.error("Failed to fetch wallet data");
    }
  };

  return (
    <div>
      {/* {walletData && ( */}
        <div>
          <div className="Table_Wrapper tableResponsive">
            <>
              {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                All Transactions List
              </h5>
            </div>


          </div> */}
              {isLoading ? (
                <PaginatedTable
                  row={walletData}
                  column={column}
                  totalCount={totalCount}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isSearch={false}
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                  maxPageNumberLimit={maxPageNumberLimit}
                  setMaxPageNumberLimit={setMaxPageNumberLimit}
                  minPageNumberLimit={minPageNumberLimit}
                  setMinPageNumberLimit={setMinPageNumberLimit}
                  isExport={false}
                  setRange={setRange}
                  range={range}
                  isDateRange={false}
                />
              ) : (
                <div style={{ textAlign: "center" }}>Loading...</div>
              )}
            </>
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default CustomerWallet;
