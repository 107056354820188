import * as Yup from "yup";
import {
  latitudeRegex,
  longitudeRegex,
  phoneRegex,
  VatIdRegex,
} from "../Regex";

export const loginSchema = Yup.object({
  email: Yup.string().email("Invalid Email Id").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    // .matches(
    //   passwordRegex,
    //   "Password must contain at least one uppercase, one lowercase, one number, and one special character"
    // )
    .min(8, "Password is at least 8 characters long"),
});

export const AddNewAdminSchema = Yup.object({
  email: Yup.string().email("Invalid Email Id").required("Email is required"),
  first_name: Yup.string().required("Firstname is required"),
  last_name: Yup.string().required("Lastname is required"),
});

export const Adduser = Yup.object({
  firstName: Yup.string()
    .required("*First Name is Required")
    .min(1, "First Name Must Be Between 1 and 50 Characters")
    .max(50, "First Name Must Be Between 1 and 50 Characters"),

  lastName: Yup.string()
    .required("*Last Name is Required")
    .min(1, "Last Name Must Be Between 1 and 50 Characters")
    .max(50, "Last Name Must Be Between 1 and 50 Characters"),

  email: Yup.string().email("Invalid Email Id").required("Email is required"),

  role: Yup.string().required("*Role is Required"),
});

export const addbrand = Yup.object({
  email: Yup.string().email("Invalid Email Id").required("Email is required"),
  name: Yup.string()
    .required("Merchant name is required")
    .max(20, "Merchant name must not exceed 20 characters"),


  logo: Yup.string().required("Logo is required"),
  contact_name: Yup.string()
    .required("Contact name is required")
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      "Only alphanumeric characters and spaces are allowed"
    ),
  website_url: Yup.string()
    .required("Website url is required")
    .matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
    ),
  // .required('Website URL is required'),

  mobile: Yup.string()
    .notRequired()
    .matches(phoneRegex, "Mobile number is not valid")
    .nullable(true)
    .required("Mobile is required"),

  merchant_vat_id: Yup.string()
    // .notRequired()
    .matches(VatIdRegex, "Vat id number is not valid")
    // .nullable(true)
    .required("Vat is required"),
  // country: Yup.string().required("Country is required"),
  city: Yup.string()
    .required("City Name is required")
    .matches(
      /^[a-zA-Z0-9\/,\s-]+$/,
      "City Name must contain only letters, numbers, slashes, hyphens, and commas"
    ),
  // password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref('password'), null], 'Passwords must match') // Check if confirmPassword matches password
  //   .required('Confirm Password is required'),
  // status: Yup.boolean()
  latitude: Yup.string()
    .matches(latitudeRegex, "Invalid Latitude")
    .nullable(true),
  longitude: Yup.string()
    .matches(longitudeRegex, "Invalid Longitude")
    .nullable(true),
  location: Yup.string().required("Location is required"),
  // android_package_name: Yup.string().required("Andriod package name is required"),
  // .matches(/^[a-zA-Z]+$/, 'Andriod Name is contain only letters'),
  // android_package_url: Yup.string().required("Andriod package url is required"),
  // .matches(
  //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  //   'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
  // ),
  // ios_package_name: Yup.string().required("Ios package name is required"),
  // .matches(/^[a-zA-Z]+$/, 'Andriod Name is contain only letters'),
  // ios_package_url: Yup.string().required("Ios package url is required"),
  // .matches(
  //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  //   'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
  // ),
  country: Yup.string().required("Country is required"),
  category_type: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      })
    )
    .min(1, "At least one category must be selected")
    .required("Category  is required"),
  reward_type: Yup.string().required("Cash back type is required"),
  store_count: Yup.number()
     
  .typeError("Store Count must be a number")
  .positive("Store Count must be greater than 0")
  .integer("Store Count must be an integer")
  .required("Store Count is required"),
  time_zone: Yup.string()
  .transform(value => (typeof value === 'object' && value !== null ? value.value : value))
  .required("TimeZone is required"), // Ensure category_type is not an empty array
  is_single_store: Yup.boolean().required("Store Type is required"),
  store_type: Yup.string().required("Merchant type is required"),

});
export const editBrand = Yup.object({
  email: Yup.string().email("Invalid Email Id").required("Email is required"),
  name: Yup.string()
    .required("Merchant name is required")
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      "Only alphanumeric characters and spaces are allowed"
    ),
  brand_logo: Yup.string().required("logo is required"),
  contact_name: Yup.string()
    .required("Contact name is required")
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      "Only alphanumeric characters and spaces are allowed"
    ),
  website_url: Yup.string()
    .required("Website url is required")
    .matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
    ),
  mobile: Yup.string()
    .notRequired()
    .matches(phoneRegex, "Mobile number is not valid")
    .nullable(true)
    .required("Mobile is required"),
  category_type: Yup.array()
    .min(1, "At least one category must be selected")
    .required("Category type is required"), // Ensure category_type is not an empty array
  latitude: Yup.string()
    .matches(latitudeRegex, "Invalid Latitude")
    .nullable(true),
  longitude: Yup.string()
    .matches(longitudeRegex, "Invalid Longitude")
    .nullable(true),
  location: Yup.string().required("Location is required"),
  // android_package_name: Yup.string().required("Andriod package name is required"),
  // .matches(/^[a-zA-Z]+$/, 'Andriod name is must contain only letters'),
  // android_package_url: Yup.string().required("Andriod package url is required"),
  // .matches(
  //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  //   'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
  // ),
  // ios_package_name: Yup.string().required("Ios package name is required"),
  // .matches(/^[a-zA-Z]+$/, 'Package Name  is contain only letters'),
  // ios_package_url: Yup.string().required("Ios package url is required"),
  // .matches(
  //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
  //   'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
  // ),
  reward_type: Yup.string().required("Cash back type is required"),
  city: Yup.string()
    .required("City name is required")
    .matches(
      /^[a-zA-Z0-9\/,\s-]+$/,
      "City Name must contain only letters, numbers, slashes, hyphens, and commas"
    ),
  country: Yup.string().required("Country is required"),
  merchant_vat_id: Yup.string()
    // .notRequired()
    .matches(VatIdRegex, "Vat id number is not valid")
    // .nullable(true)
    .required("Vat is required"),
});
export const addlocation = Yup.object({
  email: Yup.string().email("Invalid Email Id").required("Email is required"),
  // name: Yup.string().required("Merchant name is required").matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters and spaces are allowed'),
  contact_name: Yup.string()
    .required("Contact name is required")
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      "Only alphanumeric characters and spaces are allowed"
    ),

  mobile: Yup.string()
    .notRequired()
    .matches(phoneRegex, "Mobile number is not valid")
    .nullable(true)
    .required("Mobile is required"),

  city: Yup.string()
    .required("City Name is required")
    .matches(
      /^[a-zA-Z0-9\/,\s-]+$/,
      "City Name must contain only letters, numbers, slashes, hyphens, and commas"
    ),

  latitude: Yup.string()
    .matches(latitudeRegex, "Invalid Latitude")
    .nullable(true),
  longitude: Yup.string()
    .matches(longitudeRegex, "Invalid Longitude")
    .nullable(true),
  location: Yup.string().required("Location is required"),

  country: Yup.string().required("Country is required"),
});

export const addoffervalidation = Yup.object({
  CampaignName: Yup.string().required("CampaignName is required"),
  startDate: Yup.string().required("StartDate is required"),
  endDate: Yup.string().required("EndDate is required"),
  Value: Yup.number().required("Value is required"),
  percentage: Yup.string().required("Percentage is required"),
  brandName: Yup.string().required("Percentage is required"),
});
